<template>
  <div class="personal-contain">
    <!-- 导航栏区域 -->
    <NavBar
      :title="'Trung tâm thông tin cá nhân'"
      :backColor="'#ff0000'"
      class="personal-nav"
    ></NavBar>
    <!-- 单元格 -->
    <div class="personal-main">
      <van-cell-group v-for="(item, index) in personalinfo" :key="index">
        <van-cell
          :title="item.title"
          :key="index"
          :value="item.label"
          :class="index === 6 ? 'signature' : ''"
          @click="editInfo(item, index)"
        >
          <van-icon slot="right-icon" name="arrow" v-show="!(index === 1)" />
        </van-cell>
      </van-cell-group>
    </div>
    <van-dialog
      v-model="isshowDialog"
      :title="diagTitle"
      show-cancel-button
      cancel-button-text="Hủy bỏ"
      confirm-button-text="xác nhận"
      confirmButtonColor="#00aa88"
      @confirm="sureEdit"
      @cancel="editValue = ''"
    >
      <van-field
        v-model="editValue"
        autofocus="true"
        :placeholder="pleaseHodle"
        :minlength="minlength"
        v-show="isshowFileIndex.includes(slectIndex)"
      />
      <van-radio-group v-model="radio" v-show="slectIndex === 3">
        <van-cell-group>
          <van-cell title="Nam" clickable @click="radio = '1'">
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell title="Nữ" clickable @click="radio = '2'">
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <DateTiem v-show="slectIndex === 5" @dateTiem="sureEdit"></DateTiem>
    </van-dialog>
  </div>
</template>

<script>
import NavBar from '@/components/Navbar.vue'
import DateTiem from '@/components/DateTiem'

export default {
  name: 'Personal',
  components: {
    NavBar,
    DateTiem,
  },
  data() {
    return {
      personalinfo: [
        { title: 'Biểu tượng', label: '', diagTitle: '' },
        { title: 'ID', label: 34, diagTitle: '' },
        {
          title: 'Biệt danh',
          label: 'Xin vui lòng điền biệt danh',
          diagTitle: 'biệt danh',
          minlength: 4,
        },
        { title: 'Giới tính', label: '', diagTitle: 'giới tính' },
        { title: 'số điện thoại', label: '188****8899', diagtitle: '' },
        { title: 'Ngày sinh', label: '2018-09-12', diagtitle: '' },
        // {
        //   title: 'đăng nhập',
        //   label: 'Bạn chưa ký Vì vậy, hãy tiếp tục và thiết lập một cái!',
        //   diagTitle: 'đăng nhập',
        //   minlength: 10,
        // },
        // { title: 'QQ account', label: '', diagTitle: 'QQ account', minlength: 6 },
        // { title: 'Wechat account', label: '', diagTitle: 'Wechat account', minlength: 5 }
      ],
      labIndex: [3, 7, 8],
      //   控制是否显示弹出框
      isshowDialog: false,
      //   弹出框标题
      diagTitle: '',
      // 要修改的输入框的内容
      editValue: '',
      pleaseHodle: '',
      minlength: null,
      radio: '1',
      slectIndex: null,
      isshowFileIndex: [2, 6, 7, 8],
    }
  },
  mounted() {},
  created() {},
  methods: {
    //  点击每一หนึ่งสถานที่单元格触发的事件
    editInfo({ diagTitle, minlength, label }, index) {
      this.slectIndex = index
      this.isshowDialog = !(index === 0 || index === 4 || index === 1)
      this.diagTitle = diagTitle ? 'Đổi' + diagTitle : ''
      this.minlength = minlength || '0'
      this.pleaseHodle = label
      if (index === 6) {
        this.pleaseHodle = label.endsWith('!')
          ? 'Vui lòng nhập chữ ký bạn muốn sửa đổi'
          : label
      } else if (index === 7 || index === 8) {
        this.pleaseHodle =
          index === 7
            ? label || 'Vui lòng nhập tài khoản QQ của bạn.'
            : label || 'Vui lòng nhập tài khoản WeChat của bạn.'
      }
      index === 4
        ? this.$toast({
            message:
              'Để thay đổi số điện thoại, vui lòng liên hệ với dịch vụ khách hàng.',
            position: 'bottom',
          })
        : this.sureEdit()
    },

    sureEdit(value) {
      console.log(11)
      console.log(value)
      console.log(this.radio)
    },
  },
}
</script>

<style lang="less" scoped>
.personal-contain {
  position: absolute;
  left: 0;
  right: 0;
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
  /deep/ .van-nav-bar__left {
    .iconfont {
      color: #fff;
    }
  }
  .personal-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .personal-main {
    margin-top: 118px;
    .van-cell {
      height: 100px;
      padding: 30px 20px 30px 24px;
      .van-icon {
        margin-top: 8px;
        margin-left: 8px;
      }
      /deep/ .van-cell__title {
        font-size: 29px;
        color: #666;
      }
      /deep/ .van-cell__value {
        font-size: 12px;
        color: #d2d2d2;
      }
    }
    .signature {
      padding: 0 20px 0 24px;
      margin-bottom: 18px;
      /deep/ .van-cell__title {
        margin-top: 30px;
      }
      .van-icon {
        margin-top: 38px;
      }
    }
  }
  .van-field {
    border-bottom: 1px solid #666;
    height: 88px;
  }
}
</style>
